<template>
  <div>
    <div v-if="!vehiclenfo">
      <notfound :checkdata="initialLoading"></notfound>
    </div>
    <div class="container col-lg-12 auction-list-page" v-else>
      <div
        class="tab-content myaccount-tab-content"
        id="account-page-tab-content"
      >
        <div
          class=""
          id="account-dashboard"
          role="tabpanel"
          aria-labelledby="account-dashboard-tab"
          style="margin-top: 80px"
        >
          <div class="myaccount-details">
            <form action="#" class="uren-form">
              <div style="text-align: right"></div>
              <div class="uren-form-inner">
                <h3>Manage Vehicle</h3>
                <button
                  @click="goBack"
                  type="button"
                  class="back-button btn btn-primary"
                >
                  Back
                </button>
                <div class="row">
                  <div class="col-md-3">
                    <div>
                      <!-- Brand Name -->
                      <div class="single-input">
                        <label for="account-details-lastname8"
                          >Brand <span style="color: red">*</span>
                        </label>
                        <select
                          class="form-control"
                          v-model="vehiclenfo.make"
                          name="Brand"
                          v-validate="'required|max:100'"
                          @change="getModelList(vehiclenfo.make)"
                        >
                          <option disabled>Select Brand</option>
                          <option v-if="vehiclenfo.make">
                            {{ vehiclenfo.make }}
                          </option>
                          <option
                            v-for="(vhBrand, index) in VechBrand"
                            :key="index"
                            :value="vhBrand.make"
                          >
                            {{ vhBrand.make }}
                          </option>
                        </select>

                        <!-- <div style="position: relative">
                          <input
                            v-model="vehiclenfo.brandName"
                            type="text"
                            @change="clearAllFields"
                            placeholder="Enter your brand name"
                            name="Brand"
                            v-validate="'required|max:100'"
                          />
                          <div v-if="filterByBrand" class="vehSelection">
                            <div
                              v-for="(car, index) in filterByBrand"
                              :key="index"
                            >
                              <div
                                v-if="
                                  vehiclenfo.brandName != car.make &&
                                  vehiclenfo.brandName != ''
                                "
                                @click="getModelList(car.make)"
                              >
                                {{ car.make }}
                              </div>
                            </div>
                          </div>
                        </div> -->

                        <!-- <label for="statemessage" class="errorMes">{{
                          VechBrandmessage
                        }}</label> -->

                        <span
                          v-show="errors.has('Brand')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Brand") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- Model name -->
                      <div class="single-input">
                        <label for="account-details-lastname8"
                          >Model <span style="color: red">*</span>
                        </label>

                        <!-- <div style="position: relative">
                          <input
                            v-model="vehiclenfo.modelName"
                            type="text"
                            @change="clearVarientFields"
                            placeholder="Enter your model name"
                            v-validate="'required|max:100'"
                            name="Model"
                          />
                          <div v-if="filterByModel" class="vehSelection">
                            <div
                              v-for="(car, index) in filterByModel"
                              :key="index"
                            >
                              <span
                                v-if="
                                  vehiclenfo.modelName != car.model &&
                                  vehiclenfo.modelName != ''
                                "
                                @click="getVariantList(car.model)"
                                >{{ car.model }}</span
                              >
                            </div>
                          </div>
                        </div> -->

                        <select
                          class="form-control"
                          v-model="vehiclenfo.model"
                          name="Model"
                          v-validate="'required|max:100'"
                          @change="getVariantList(vehiclenfo.model)"
                        >
                          <option disabled>Select Model</option>
                          <option v-if="vehiclenfo.model">
                            {{ vehiclenfo.model }}
                          </option>
                          <option
                            v-for="(vhMod, index) in VechModel"
                            :key="index"
                            :value="vhMod.model"
                          >
                            {{ vhMod.model }}
                          </option>
                        </select>

                        <!-- <label for="statemessage" class="errorMes">{{
                          VechModelmessage
                        }}</label> -->
                        <span
                          v-show="errors.has('Model')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Model") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- Variant  -->
                      <div class="single-input">
                        <label for="account-details-lastname0"
                          >Variant <span style="color: red">*</span>
                        </label>

                        <select
                          class="form-control"
                          v-model="vehiclenfo.variant"
                          name="Variant"
                          v-validate="'required|max:100'"
                        >
                          <option disabled>Select Variant</option>
                          <option v-if="vehiclenfo.variant">
                            {{ vehiclenfo.variant }}
                          </option>
                          <option
                            v-for="(vhVariant, index) in VechVariant"
                            :key="index"
                            :value="vhVariant.variant"
                          >
                            {{ vhVariant.variant }}
                          </option>
                        </select>
                        <!-- <label for="statemessage" class="errorMes">{{
                          VechTypemessage
                        }}</label> -->
                        <span
                          v-show="errors.has('Variant')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Variant") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- vehicle type  -->
                      <div class="single-input">
                        <label for="account-details-lastname0"
                          >Vehicle Types <span style="color: red">*</span>
                        </label>

                        <select
                          class="form-control"
                          v-model="st.vehTypeId"
                          name="Vehicle Type"
                          v-validate="'required|max:100'"
                        >
                          <option value="" selected disabled>
                            Select Vehicle Type
                          </option>
                          <option
                            v-for="st in VechType"
                            :value="st.vehTypeId"
                            :key="st.vehTypeId"
                            v-validate="'required|max:100'"
                          >
                            {{ st.vehTypeName }}
                          </option>
                        </select>
                        <!-- <label for="errorMes" class="errorMes">{{
                              VechVariantemessage
                            }}</label> -->
                        <span
                          v-show="errors.has('Vehicle Type')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Vehicle Type") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- Insurance Type -->
                      <div class="single-input">
                        <label for="account-details-lastname8">
                          Insurance Type
                        </label>
                        <select
                          class="form-control"
                          v-model="vehiclenfo.InsuranceTypeId"
                          name="Insurance Type"
                        >
                          <option disabled>Select insurance type</option>
                          <option
                            v-for="(st, index) in InsuranceTypeList"
                            :value="st.InsuranceTypeId"
                            :key="index"
                          >
                            {{ st.InsuranceTypeName }}
                          </option>
                        </select>
                        <!-- <label for="statemessage" class="errorMes">{{
                          VechTypemessage
                        }}</label> -->
                        <span
                          v-show="errors.has('Insurance Type')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Insurance Type") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <div class="single-input">
                        <label for="account-details-lastname1">Color </label>

                        <input
                          type="text"
                          name="Color"
                          id="account-details-lastnae2"
                          v-model="vehiclenfo.Color"
                          placeholder="Color"
                        />
                        <span
                          v-show="errors.has('Color')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Color") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <!-- HP -->
                    <div class="single-input">
                      <label for="account-details-lastname1"
                        >Transmission</label
                      >
                      <!-- <input
                        name="Transmission"
                        id="account-details-lastnae2"
                        v-model="vehiclenfo.HP"
                        placeholder="HP"
                      /> -->

                      <select
                        class="form-control"
                        name="Transmission"
                        v-model="vehiclenfo.HP"
                        v-validate="'required'"
                      >
                        <option disabled>Select transmission type</option>
                        <option value="Manual">Manual</option>
                        <option value="Automatic">Automatic</option>
                      </select>
                      <span
                        v-show="errors.has('Transmission')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Transmission") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <!-- Registration Number* -->
                    <div class="single-input">
                      <label for="account-details-lastname1"
                        >Registration Number <span style="color: red">*</span>
                        <!-- <span>MP 09 AB 1234/MP 09 B 1234</span> -->
                      </label>
                      <input
                        type="text"
                        name="Registration Number"
                        v-validate="'required|min:3|max:100'"
                        placeholder="MP 09 AB 1234"
                        id="account-details-lastnae2"
                        v-model="vehiclenfo.vehRegNo"
                      />
                      <!-- <input
                    type="text"
                    name="vehRegNo"
                    v-validate="{
                      required: true,
                      regex:
                        /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/,
                    }"
                    placeholder="MP 09 AB 1234"
                    id="account-details-lastnae2"
                    v-model="vehiclenfo.vehRegNo"
                  /> -->
                      <span
                        v-show="errors.has('Registration Number')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Registration Number") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-firstname2"
                        >Km Clocked <span style="color: red">*</span>
                      </label>
                      <input
                        name="KM Clocked"
                        v-validate="'required|max:20|numeric'"
                        id="account-details-firstname2"
                        v-model="vehiclenfo.kmClocked"
                        placeholder="Km Clocked"
                      />
                      <span
                        v-show="errors.has('KM Clocked')"
                        class="help is-danger errorMes"
                        >{{ errors.first("KM Clocked") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-lastne3"
                        >Vehicle Price <span style="color: red">*</span>
                      </label>
                      <input
                        name="Price"
                        v-validate="'required|min:3|max:20|numeric'"
                        id="account-details-lasame3"
                        v-model="vehiclenfo.vehPrice"
                        placeholder="Price"
                      />
                      <span
                        v-show="errors.has('Price')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Price") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-firstname4"
                        >Year of make <span style="color: red">*</span>
                      </label>
                      <input
                        name="Year of make"
                        id="account-details-firstname4"
                        v-model="vehiclenfo.manYear"
                        placeholder="Year of make"
                        v-validate="'required|max:20|numeric'"
                      />
                      <span
                        v-show="errors.has('Year of make')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Year of make") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-lastname8"
                        >State <span style="color: red">*</span>
                      </label>
                      <select
                        class="form-control"
                        @change="getDistrictbyLocId($event)"
                        v-model="st.state"
                        name="State"
                        v-validate="'required'"
                      >
                        <option disabled>Select state</option>
                        <option selected>{{ vehiclenfo.state }}</option>
                        <option
                          v-for="st in States"
                          :value="st.state"
                          :key="st.state"
                        >
                          {{ st.state }}
                        </option>
                      </select>
                      <!-- <label for="statemessage">{{ statemessage }}</label>
                       -->
                      <span
                        v-show="errors.has('State')"
                        class="help is-danger errorMes"
                        >{{ errors.first("State") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-lastname8"
                        >Districts <span style="color: red">*</span>
                      </label>
                      <select
                        class="form-control"
                        @change="getLocationByStateId($event)"
                        v-model="st.district"
                        name="District"
                        v-validate="'required'"
                      >
                        <option disabled>Select district</option>
                        <option selected>{{ vehiclenfo.district }}</option>
                        <option
                          v-for="st in Districts"
                          :value="st.district"
                          :key="st.district"
                        >
                          {{ st.district }}
                        </option>
                      </select>
                      <!-- <label for="districtmessage" class="errorMes">{{
                        districtmessage
                      }}</label> -->
                      <span
                        v-show="errors.has('District')"
                        class="help is-danger errorMes"
                        >{{ errors.first("District") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="single-input">
                      <label for="account-details-lastnam"
                        >City <span style="color: red">*</span>
                      </label>
                      <select
                        class="form-control"
                        v-model="vehiclenfo.locName"
                        name="City"
                        v-validate="'required'"
                      >
                        <option disabled>Select city</option>
                        <option selected>{{ vehiclenfo.locName }}</option>
                        <option
                          v-for="st in City"
                          :value="st.locName"
                          :key="st.locId"
                        >
                          {{ st.locName }}
                        </option>
                      </select>
                      <!-- <label for="citymessage" class="errorMes">{{
                        citymessage
                      }}</label> -->
                      <span
                        v-show="errors.has('City')"
                        class="help is-danger errorMes"
                        >{{ errors.first("City") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- Fuel Type -->
                      <div class="single-input">
                        <label for="account-details-lastname8">
                          Fuel Type <span style="color: red">*</span>
                        </label>
                        <select
                          class="form-control"
                          v-model="vehiclenfo.fuelType"
                          name="Fuel Type"
                          v-validate="'required'"
                        >
                          <!-- v-validate="'required|max:100'" -->
                          <option value="" selected disabled>
                            Select Fuel Types
                          </option>
                          <option
                            v-for="vehiclenfo in FuelType"
                            :value="vehiclenfo.fuelTypeId"
                            :key="vehiclenfo.fuelTypeId"
                          >
                            {{ vehiclenfo.fuelTypeName }}
                          </option>
                        </select>
                        <!-- <label for="errorMes" class="errorMes">{{
                              Vechinsurancemessage
                            }}</label> -->
                        <span
                          v-show="errors.has('Fuel Type')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Fuel Type") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- Ownership -->
                      <div class="single-input">
                        <label for="account-details-lastname8">
                          Ownership <span style="color: red">*</span>
                        </label>
                        <select
                          class="form-control"
                          v-model="vehiclenfo.ownership"
                          name="Ownership"
                          v-validate="'required'"
                        >
                          <!-- v-validate="'required|max:100'" -->
                          <option value="" selected disabled>
                            Select Ownership
                          </option>
                          <option value="Owner 1">Owner 1</option>
                          <option value="Owner 2">Owner 2</option>
                          <option value="Owner 3">Owner 3</option>
                          <option value="Owner 4">Owner 4</option>
                        </select>
                        <span
                          v-show="errors.has('Ownership')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Ownership") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-6">
                    <div class="single-input">
                      <label for="account-details-lastname8">ABS</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="absState in radioButton"
                        :key="absState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="absState.id"
                          class="radio-input"
                          name="ABS"
                          v-model="vehiclenfo.abs"
                        />
                        <span class="radio-label">{{ absState.name }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("abs") }}
                      </div> -->
                      <span
                        v-show="errors.has('ABS')"
                        class="help is-danger errorMes"
                        >{{ errors.first("ABS") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Accidental -->
                    <div class="single-input">
                      <label for="account-details-lastname8">Accidental</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="accidentalState in radioButton"
                        :key="accidentalState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="accidentalState.id"
                          class="radio-input"
                          name="Accidental"
                          v-model="vehiclenfo.accidental"
                        />
                        <span class="radio-label">{{
                          accidentalState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("accidental") }}
                      </div> -->
                      <span
                        v-show="errors.has('Accidental')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Accidental") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Adjustable Steering -->
                    <div class="single-input">
                      <label for="account-details-lastname8"
                        >Roof AC</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="adjustableSteeringState in radioButton"
                        :key="adjustableSteeringState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="adjustableSteeringState.id"
                          class="radio-input"
                          name="Roof AC"
                          v-model="vehiclenfo.adjustableSteering"
                        />
                        <span class="radio-label">{{
                          adjustableSteeringState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("adjustableSteering") }}
                      </div>
                      <span
                        v-show="errors.has('Adjustable Steering')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Adjustable Steering") }}</span
                      > -->
                      <span
                        v-show="errors.has('Roof AC')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Roof AC") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <div>
                      <!-- Alloy Wheels -->
                      <div class="single-input">
                        <label for="account-details-lastname8">
                          Alloy Wheels</label
                        >
                        <label
                          style="display: inline-block; margin-right: 12px"
                          class="radio is-inline"
                          v-for="alloyWheelsState in radioButton"
                          :key="alloyWheelsState.id"
                        >
                          <input
                            style="margin-top: 0px"
                            type="radio"
                            :value="alloyWheelsState.id"
                            class="radio-input"
                            name="Alloy Wheels"
                            v-model="vehiclenfo.alloyWheels"
                          />
                          <span class="radio-label">{{
                            alloyWheelsState.name
                          }}</span>
                        </label>
                        <!-- <div class="errorMes">
                          {{ errors.first("alloyWheels") }}
                        </div>
                        <span
                          v-show="errors.has('Alloy Wheels')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Alloy Wheels") }}</span
                        > -->
                        <span
                          v-show="errors.has('Alloy Wheels')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Alloy Wheels") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <div>
                      <!-- Anti Theft Device -->
                      <div class="single-input">
                        <label for="account-details-lastname8">
                          Anti Theft
                        </label>
                        <label
                          style="display: inline-block; margin-right: 12px"
                          class="radio is-inline"
                          v-for="antiTheftDeviceState in radioButton"
                          :key="antiTheftDeviceState.id"
                        >
                          <input
                            style="margin-top: 0px"
                            type="radio"
                            :value="antiTheftDeviceState.id"
                            class="radio-input"
                            name="Anti Theft"
                            v-model="vehiclenfo.antiTheftDevice"
                          />
                          <span class="radio-label">{{
                            antiTheftDeviceState.name
                          }}</span>
                        </label>
                        <!-- <div class="errorMes">
                          {{ errors.first("antiTheftDevice") }}
                        </div>
                        <span
                          v-show="errors.has('Anti Theft Device')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Anti Theft Device") }}</span
                        > -->
                        <span
                          v-show="errors.has('Anti Theft')"
                          class="help is-danger errorMes"
                          >{{ errors.first("Anti Theft") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Aux Compatibility -->
                    <div class="single-input">
                      <label for="account-details-lastname8"> Power window </label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="auxCompatibilityState in radioButton"
                        :key="auxCompatibilityState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="auxCompatibilityState.id"
                          class="radio-input"
                          name="Power window"
                          v-model="vehiclenfo.auxCompatibility"
                        />
                        <span class="radio-label">{{
                          auxCompatibilityState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("auxCompatibility") }}
                      </div>
                      <span
                        v-show="errors.has('Aux Compatibility')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Aux Compatibility") }}</span
                      > -->
                      <span
                        v-show="errors.has('Power window')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Power window") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Bluetooth -->
                    <div class="single-input">
                      <label for="account-details-lastname8"> Rear Wiper</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="bluetoothState in radioButton"
                        :key="bluetoothState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="bluetoothState.id"
                          class="radio-input"
                          name="Rear Wiper"
                          v-model="vehiclenfo.bluetooth"
                        />
                        <span class="radio-label">{{
                          bluetoothState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("bluetooth") }}
                      </div>
                      <span
                        v-show="errors.has('Bluetooth')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Bluetooth") }}</span
                      > -->
                      <span
                        v-show="errors.has('Rear Wiper')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Rear Wiper") }}</span
                      >
                    </div>
                  </div>

                  <div class="col-md-2 col-6">
                    <!-- Comprehensive Navigation System -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Navigation System</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="comprehensiveNavigationSystemState in radioButton"
                        :key="comprehensiveNavigationSystemState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="comprehensiveNavigationSystemState.id"
                          class="radio-input"
                          name="Navigation System"
                          v-model="vehiclenfo.navigationSystem"
                        />
                        <span class="radio-label">{{
                          comprehensiveNavigationSystemState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("comprehensiveNavigationSystem") }}
                      </div>
                      <span
                        v-show="errors.has('Comprehensive Navigation System')"
                        class="help is-danger errorMes"
                        >{{
                          errors.first("Comprehensive Navigation System")
                        }}</span
                      > -->
                      <span
                        v-show="errors.has('Navigation System')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Navigation System") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Parking Sensors -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Parking Sensors</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="parkingSensorsState in radioButton"
                        :key="parkingSensorsState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="parkingSensorsState.id"
                          class="radio-input"
                          name="Parking Sensors"
                          v-model="vehiclenfo.parkingSensors"
                        />
                        <span class="radio-label">{{
                          parkingSensorsState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("parkingSensors") }}
                      </div>
                      <span
                        v-show="errors.has('Parking Sensors')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Parking Sensors") }}</span
                      > -->
                      <span
                        v-show="errors.has('Parking Sensors')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Parking Sensors") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Power steering -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Power steering</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="powerSteeringState in radioButton"
                        :key="powerSteeringState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="powerSteeringState.id"
                          class="radio-input"
                          name="Power steering"
                          v-model="vehiclenfo.powerSteering"
                        />
                        <span class="radio-label">{{
                          powerSteeringState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("powerSteering") }}
                      </div>
                      <span
                        v-show="errors.has('Power steering')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Power steering") }}</span
                      > -->
                      <span
                        v-show="errors.has('Power steering')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Power steering") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- AM/FM Radio -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Music system</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="AMFMRadioState in radioButton"
                        :key="AMFMRadioState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="AMFMRadioState.id"
                          class="radio-input"
                          name="Music system"
                          v-model="vehiclenfo.FMRadio"
                        />
                        <span class="radio-label">{{
                          AMFMRadioState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("AMFMRadio") }}
                      </div>
                      <span
                        v-show="errors.has('AM/FM Radio')"
                        class="help is-danger errorMes"
                        >{{ errors.first("AM/FM Radio") }}</span
                      > -->
                      <span
                        v-show="errors.has('Music system')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Music system") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Rear Parking Camera -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Rear Parking Camera</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="rearParkingCameraState in radioButton"
                        :key="rearParkingCameraState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="rearParkingCameraState.id"
                          class="radio-input"
                          name="Rear Parking Camera"
                          v-model="vehiclenfo.rearParkingCamera"
                        />
                        <span class="radio-label">{{
                          rearParkingCameraState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("rearParkingCameraState") }}
                      </div>
                      <span
                        v-show="errors.has('Rear_Parking_Camera')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Rear_Parking_Camera") }}</span
                      > -->
                      <span
                        v-show="errors.has('Rear Parking Camera')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Rear Parking Camera") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Exchange -->
                    <div class="single-input">
                      <label for="account-details-lastname8"> Key less entry</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="exchangeState in radioButton"
                        :key="exchangeState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="exchangeState.id"
                          class="radio-input"
                          name="Key less entry"
                          v-model="vehiclenfo.exchange"
                        />
                        <span class="radio-label">{{
                          exchangeState.name
                        }}</span>
                      </label>
                      <span
                        v-show="errors.has('Key less entry')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Key less entry") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <div class="single-input">
                      <label for="account-details-lastname8"> Remote control</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="financeState in radioButton"
                        :key="financeState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="financeState.id"
                          class="radio-input"
                          name="Remote control"
                          v-model="vehiclenfo.finance"
                        />
                        <span class="radio-label">{{ financeState.name }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("financeState") }}
                      </div>
                      <span
                        v-show="errors.has('Finance')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Finance") }}</span
                      > -->
                      <span
                        v-show="errors.has('Remote control')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Remote control") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Service History -->
                    <div class="single-input">
                      <label for="account-details-lastname8"
                        >Service History <span style="color: red"
                          >*</span
                        >
                      </label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="serviceHistoryState in radioButton"
                        :key="serviceHistoryState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="serviceHistoryState.id"
                          class="radio-input"
                          v-validate="'required'"
                          name="Service History"
                          v-model="vehiclenfo.serviceHistory"
                        />
                        <span class="radio-label">{{
                          serviceHistoryState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("serviceHistoryState") }}
                      </div>
                      <span
                        v-show="errors.has('Service_History')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Service_History") }}</span
                      > -->
                      <span
                        v-show="errors.has('Service History')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Service History") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Sunroof-->
                    <div class="single-input">
                      <label for="account-details-lastname8"> Sunroof</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="sunroofSate in radioButton"
                        :key="sunroofSate.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="sunroofSate.id"
                          class="radio-input"
                          name="Sunroof"
                          v-model="vehiclenfo.sunroof"
                        />
                        <span class="radio-label">{{ sunroofSate.name }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("sunroof") }}
                      </div>
                      <span
                        v-show="errors.has('Sunroof')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Sunroof") }}</span
                      > -->
                      <span
                        v-show="errors.has('Sunroof')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Sunroof") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- USB Compatibility -->
                    <div class="single-input">
                      <label for="account-details-lastname8">  Cruise control </label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="usebSate in radioButton"
                        :key="usebSate.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="usebSate.id"
                          class="radio-input"
                          name="Cruise control"
                          v-model="vehiclenfo.usb"
                        />
                        <span class="radio-label">{{ usebSate.name }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("usbState") }}
                      </div>
                      <span
                        v-show="errors.has('USB_Compatibility')"
                        class="help is-danger errorMes"
                        >{{ errors.first("USB_Compatibility") }}</span
                      > -->

                      <span
                        v-show="errors.has('Cruise control')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Cruise control") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Warranty -->
                    <div class="single-input">
                      <label for="account-details-lastname8">Warranty</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="WarrantyState in radioButton"
                        :key="WarrantyState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="WarrantyState.id"
                          class="radio-input"
                          name="Warranty"
                          v-model="vehiclenfo.underWarantee"
                        />
                        <span class="radio-label">{{
                          WarrantyState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("WarrantyState") }}
                      </div>
                      <span
                        v-show="errors.has('Waranty')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Waranty") }}</span
                      > -->
                      <span
                        v-show="errors.has('Warranty')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Warranty") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Vehicle Certified -->
                    <div class="single-input">
                      <label for="account-details-lastname8">
                        Vehicle Certified</label
                      >
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="vehicleCertifiedState in radioButton"
                        :key="vehicleCertifiedState.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="vehicleCertifiedState.id"
                          class="radio-input"
                          name="Vehicle Certified"
                          v-model="vehiclenfo.vehicleCertified"
                        />
                        <span class="radio-label">{{
                          vehicleCertifiedState.name
                        }}</span>
                      </label>
                      <!-- <div class="errorMes">
                        {{ errors.first("vehicleCertified") }}
                      </div>
                      <span
                        v-show="errors.has('Vehicle Certified')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Vehicle Certified") }}</span
                      > -->
                      <span
                        v-show="errors.has('Vehicle Certified')"
                        class="help is-danger errorMes"
                        >{{ errors.first("Vehicle Certified") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-6">
                    <!-- Vehicle Certified -->
                    <div class="single-input">
                      <label for="account-details-lastname8"> AC</label>
                      <label
                        style="display: inline-block; margin-right: 12px"
                        class="radio is-inline"
                        v-for="ac in radioButton"
                        :key="ac.id"
                      >
                        <input
                          style="margin-top: 0px"
                          type="radio"
                          :value="ac.id"
                          class="radio-input"
                          name="AC"
                          v-model="st.AC"
                        />
                        <span class="radio-label">{{ ac.name }}</span>
                      </label>

                      <span
                        v-show="errors.has('AC')"
                        class="help is-danger errorMes"
                        >{{ errors.first("AC") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="single-input">
                  <label>Vehicle History(PDF/Image)</label>
                  <div v-if="vehiclenfo.vehicleHistoryUrl">
                    <a
                      :href="path + vehiclenfo.vehicleHistoryUrl"
                      target="_blank"
                      >Download</a
                    >
                  </div>

                  <input
                    class="form-control historyImagesCls"
                    type="file"
                    name="historyImages"
                    accept="image/png,application/pdf,image/jpeg"
                    id="imageVeHisinput"
                    @change="onFileVehicleHistoryChange"
                  />
                  <label for="account-details-lastnam">Dealer notes</label>
                  <textarea
                    name="Dealer notes"
                    id=""
                    style="width: 100%; height: 100px"
                    v-model="vehiclenfo.vehdescription"
                    placeholder="Dealer notes"
                  >
                  </textarea>
                  <span
                    v-show="errors.has('Dealer notes')"
                    class="help is-danger errorMes"
                    >{{ errors.first("Dealer notes") }}</span
                  >
                </div>
                <div class="single-input single-input-half">
                  <label
                    style="background-color: #02ad10; margin: 0px"
                    class="uren-btn uren-btn_dark"
                    type="button"
                    v-if="vehiclenfo.status == true"
                    for="vechApproval"
                    >Approved
                  </label>
                  <label
                    style="background-color: #f26b61; margin: 0px"
                    class="uren-btn uren-btn_dark"
                    type="button"
                    v-else
                    for="vechApproval"
                  >
                    Not Approved
                  </label>
                </div>

                <div
                  v-if="vehiclenfo.auctionStatus"
                  class="vehicle-details-auction-status"
                >
                  <p>{{ vehiclenfo.auctionStatus }}</p>
                </div>

                <div>
                  <div
                    class="imagesfromdb"
                    style="display: flex; flex-wrap: wrap; gap: 8px"
                  >
                    <div
                      v-if="
                        !isHidden &&
                        vehiclenfo.vehImage1 != null &&
                        vehiclenfo.vehImage1 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage1"
                        class="loadImage"
                        id="image1"
                      />
                      <button
                        @click="Hidden1"
                        class="btnImageRemove"
                        v-on:click="isHidden = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>

                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="0"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange1"
                      />
                      <div v-if="images1">
                        <img :src="images1[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="0"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        !isHidden2 &&
                        vehiclenfo.vehImage2 != null &&
                        vehiclenfo.vehImage2 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage2"
                        class="loadImage"
                        id="image2"
                      />
                      <button
                        @click="Hidden2"
                        class="btnImageRemove"
                        v-on:click="isHidden2 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="1"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange2"
                      />
                      <div v-if="images2">
                        <img :src="images2[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="1"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        !isHidden3 &&
                        vehiclenfo.vehImage3 != null &&
                        vehiclenfo.vehImage3 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage3"
                        class="loadImage"
                        id="image3"
                      />
                      <button
                        @click="Hidden3"
                        class="btnImageRemove"
                        v-on:click="isHidden3 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="2"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange3"
                      />
                      <div v-if="images3">
                        <img :src="images3[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="2"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        !isHidden4 &&
                        vehiclenfo.vehImage4 != null &&
                        vehiclenfo.vehImage4 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage4"
                        class="loadImage"
                        id="image4"
                      />
                      <button
                        @click="Hidden4"
                        class="btnImageRemove"
                        v-on:click="isHidden4 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="3"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange4"
                      />
                      <div v-if="images4">
                        <img :src="images4[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="3"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden5 &&
                        vehiclenfo.vehImage5 != null &&
                        vehiclenfo.vehImage5 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage5"
                        class="loadImage"
                        id="image5"
                      />
                      <button
                        @click="Hidden5"
                        class="btnImageRemove"
                        v-on:click="isHidden5 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="4"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange5"
                      />
                      <div v-if="images5">
                        <img :src="images5[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="4"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden6 &&
                        vehiclenfo.vehImage6 != null &&
                        vehiclenfo.vehImage6 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage6"
                        class="loadImage"
                        id="image6"
                      />
                      <button
                        @click="Hidden6"
                        class="btnImageRemove"
                        v-on:click="isHidden6 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="5"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange6"
                      />
                      <div v-if="images6">
                        <img :src="images6[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="5"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden7 &&
                        vehiclenfo.vehImage7 != null &&
                        vehiclenfo.vehImage7 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage7"
                        class="loadImage"
                        id="image7"
                      />
                      <button
                        @click="Hidden7"
                        class="btnImageRemove"
                        v-on:click="isHidden7 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="6"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange7"
                      />
                      <div v-if="images7">
                        <img :src="images7[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="6"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden8 &&
                        vehiclenfo.vehImage8 != null &&
                        vehiclenfo.vehImage8 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage8"
                        class="loadImage"
                        id="image8"
                      />
                      <button
                        @click="Hidden8"
                        class="btnImageRemove"
                        v-on:click="isHidden8 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="7"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange8"
                      />
                      <div v-if="images8">
                        <img :src="images8[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="7"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden9 &&
                        vehiclenfo.vehImage9 != null &&
                        vehiclenfo.vehImage9 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage9"
                        class="loadImage"
                        id="image9"
                      />
                      <button
                        @click="Hidden9"
                        class="btnImageRemove"
                        v-on:click="isHidden9 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="8"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange9"
                      />
                      <div v-if="images9">
                        <img :src="images9[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="8"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden10 &&
                        vehiclenfo.vehImage10 != null &&
                        vehiclenfo.vehImage10 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage10"
                        class="loadImage"
                        id="image10"
                      />
                      <button
                        @click="Hidden10"
                        class="btnImageRemove"
                        v-on:click="isHidden10 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="9"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange10"
                      />
                      <div v-if="images10">
                        <img :src="images10[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="9"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden11 &&
                        vehiclenfo.vehImage11 != null &&
                        vehiclenfo.vehImage11 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage11"
                        class="loadImage"
                        id="image11"
                      />
                      <button
                        @click="Hidden11"
                        class="btnImageRemove"
                        v-on:click="isHidden11 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="10"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange11"
                      />
                      <div v-if="images11">
                        <img :src="images11[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="10"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden12 &&
                        vehiclenfo.vehImage12 != null &&
                        vehiclenfo.vehImage12 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage12"
                        class="loadImage"
                        id="image12"
                      />
                      <button
                        @click="Hidden12"
                        class="btnImageRemove"
                        v-on:click="isHidden12 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="11"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange12"
                      />
                      <div v-if="images12">
                        <img :src="images12[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="11"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden13 &&
                        vehiclenfo.vehImage13 != null &&
                        vehiclenfo.vehImage13 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage13"
                        class="loadImage"
                        id="image13"
                      />
                      <button
                        @click="Hidden13"
                        class="btnImageRemove"
                        v-on:click="isHidden13 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="12"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange13"
                      />
                      <div v-if="images13">
                        <img :src="images13[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="12"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden14 &&
                        vehiclenfo.vehImage14 != null &&
                        vehiclenfo.vehImage14 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage14"
                        class="loadImage"
                        id="image14"
                      />
                      <button
                        @click="Hidden14"
                        class="btnImageRemove"
                        v-on:click="isHidden14 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="13"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange14"
                      />
                      <div v-if="images14">
                        <img :src="images14[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="13"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden16 &&
                        vehiclenfo.vehImage15 != null &&
                        vehiclenfo.vehImage15 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage15"
                        class="loadImage"
                        id="image15"
                      />
                      <button
                        @click="Hidden15"
                        class="btnImageRemove"
                        v-on:click="isHidden15 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="14"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange15"
                      />
                      <div v-if="images15">
                        <img :src="images15[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="14"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden16 &&
                        vehiclenfo.vehImage16 != null &&
                        vehiclenfo.vehImage16 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage16"
                        class="loadImage"
                        id="image16"
                      />
                      <button
                        @click="Hidden16"
                        class="btnImageRemove"
                        v-on:click="isHidden16 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="15"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange16"
                      />
                      <div v-if="images16">
                        <img :src="images16[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="15"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden17 &&
                        vehiclenfo.vehImage17 != null &&
                        vehiclenfo.vehImage17 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage17"
                        class="loadImage"
                        id="image17"
                      />
                      <button
                        @click="Hidden17"
                        class="btnImageRemove"
                        v-on:click="isHidden17 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="16"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange17"
                      />
                      <div v-if="images17">
                        <img :src="images17[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="16"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden18 &&
                        vehiclenfo.vehImage18 != null &&
                        vehiclenfo.vehImage18 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage18"
                        class="loadImage"
                        id="image18"
                      />
                      <button
                        @click="Hidden18"
                        class="btnImageRemove"
                        v-on:click="isHidden18 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="17"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange18"
                      />
                      <div v-if="images18">
                        <img :src="images18[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="17"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden19 &&
                        vehiclenfo.vehImage19 != null &&
                        vehiclenfo.vehImage19 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage19"
                        class="loadImage"
                        id="image19"
                      />
                      <button
                        @click="Hidden19"
                        class="btnImageRemove"
                        v-on:click="isHidden19 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="18"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange19"
                      />
                      <div v-if="images19">
                        <img :src="images19[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="18"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden20 &&
                        vehiclenfo.vehImage20 != null &&
                        vehiclenfo.vehImage20 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage20"
                        class="loadImage"
                        id="image20"
                      />
                      <button
                        @click="Hidden20"
                        class="btnImageRemove"
                        v-on:click="isHidden20 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="19"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange20"
                      />
                      <div v-if="images20">
                        <img :src="images20[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="19"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden21 &&
                        vehiclenfo.vehImage21 != null &&
                        vehiclenfo.vehImage21 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage21"
                        class="loadImage"
                        id="image21"
                      />
                      <button
                        @click="Hidden21"
                        class="btnImageRemove"
                        v-on:click="isHidden21 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="20"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange21"
                      />
                      <div v-if="images21">
                        <img :src="images21[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="20"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden22 &&
                        vehiclenfo.vehImage22 != null &&
                        vehiclenfo.vehImage22 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage22"
                        class="loadImage"
                        id="image22"
                      />
                      <button
                        @click="Hidden22"
                        class="btnImageRemove"
                        v-on:click="isHidden22 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="21"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange22"
                      />
                      <div v-if="images22">
                        <img :src="images22[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="21"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden23 &&
                        vehiclenfo.vehImage23 != null &&
                        vehiclenfo.vehImage23 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage23"
                        class="loadImage"
                        id="image23"
                      />
                      <button
                        @click="Hidden23"
                        class="btnImageRemove"
                        v-on:click="isHidden23 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="22"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange23"
                      />
                      <div v-if="images23">
                        <img :src="images23[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="22"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden24 &&
                        vehiclenfo.vehImage24 != null &&
                        vehiclenfo.vehImage24 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage24"
                        class="loadImage"
                        id="image24"
                      />
                      <button
                        @click="Hidden24"
                        class="btnImageRemove"
                        v-on:click="isHidden24 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="23"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange24"
                      />
                      <div v-if="images24">
                        <img :src="images24[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="23"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden25 &&
                        vehiclenfo.vehImage25 != null &&
                        vehiclenfo.vehImage25 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage25"
                        class="loadImage"
                        id="image25"
                      />
                      <button
                        @click="Hidden25"
                        class="btnImageRemove"
                        v-on:click="isHidden25 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="24"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange25"
                      />
                      <div v-if="images25">
                        <img :src="images25[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="24"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden26 &&
                        vehiclenfo.vehImage26 != null &&
                        vehiclenfo.vehImage26 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage26"
                        class="loadImage"
                        id="image26"
                      />
                      <button
                        @click="Hidden26"
                        class="btnImageRemove"
                        v-on:click="isHidden26 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="25"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange26"
                      />
                      <div v-if="images26">
                        <img :src="images26[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="25"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden27 &&
                        vehiclenfo.vehImage27 != null &&
                        vehiclenfo.vehImage27 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage27"
                        class="loadImage"
                        id="image27"
                      />
                      <button
                        @click="Hidden27"
                        class="btnImageRemove"
                        v-on:click="isHidden27 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="26"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange27"
                      />
                      <div v-if="images27">
                        <img :src="images27[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="26"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden28 &&
                        vehiclenfo.vehImage28 != null &&
                        vehiclenfo.vehImage28 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage28"
                        class="loadImage"
                        id="image28"
                      />
                      <button
                        @click="Hidden28"
                        class="btnImageRemove"
                        v-on:click="isHidden28 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="27"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange28"
                      />
                      <div v-if="images28">
                        <img :src="images28[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="27"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-if="
                        !isHidden29 &&
                        vehiclenfo.vehImage29 != null &&
                        vehiclenfo.vehImage29 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage29"
                        class="loadImage"
                        id="image29"
                      />
                      <button
                        @click="Hidden29"
                        class="btnImageRemove"
                        v-on:click="isHidden29 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="28"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange29"
                      />
                      <div v-if="images29">
                        <img :src="images29[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="28"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div
                      v-if="
                        !isHidden30 &&
                        vehiclenfo.vehImage30 != null &&
                        vehiclenfo.vehImage30 != ''
                      "
                    >
                      <img
                        :src="path + vehiclenfo.vehImage30"
                        class="loadImage"
                        id="image30"
                      />
                      <button
                        @click="Hidden30"
                        class="btnImageRemove"
                        v-on:click="isHidden30 = true"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Remove
                      </button>
                      <div class="image-actions">
                        <input
                          type="radio"
                          v-model="defaultImageIndex"
                          :value="29"
                        />
                        Set as Default
                      </div>
                    </div>
                    <div v-else>
                      <input
                        type="file"
                        class="fileSelect"
                        @change="onFileChange30"
                      />
                      <div v-if="images30">
                        <img :src="images30[0]" class="loadImage" />
                        <div class="image-actions">
                          <input
                            type="radio"
                            v-model="defaultImageIndex"
                            :value="29"
                          />
                          Set as Default
                        </div>
                      </div>
                    </div>
                    <!--  -->
                  </div>
                </div>

                <div>
                  <label
                    for="alertmessage"
                    class="errorMes"
                    v-if="imagesmessage"
                    >{{ imagesmessage }}</label
                  >
                </div>
                <div class="single-input smallscreenbtn" style="display: flex">
                  <b-button
                    v-if="
                      vehiclenfo.isDeletable === 1 ||
                      vehiclenfo.isDeletable === null ||
                      vehiclenfo.isDeletable === ''
                    "
                    style="background-color: #0094da"
                    @click="updateVechDetails()"
                    class="uren-btn uren-btn_dark"
                    type="button"
                  >
                    <span style="font-weight: 500">Save Changes</span>
                  </b-button>
                  <button
                    v-if="
                      vehiclenfo.isDeletable === 1 ||
                      vehiclenfo.isDeletable === null ||
                      vehiclenfo.isDeletable === ''
                    "
                    style="background-color: #cf0000"
                    type="button"
                    class="uren-btn uren-btn_dark"
                    @click="deleteVehicle()"
                  >
                    <span>Delete</span>
                  </button>
                  <router-link
                    to="/MyVehicleListings"
                    style="background-color: rgb(68 68 68)"
                    class="uren-btn uren-btn_dark"
                    type="button"
                  >
                    <span style="font-size: 15px">Back</span>
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <b-modal
            id="modalsave"
            ref="modalsave"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
          >
            <div v-if="message">
              <form ref="form">
                <div>
                  <div class="form-group" v-if="message == 'Success'">
                    <!-- <div class="alert alert-warning" role="alert">
                        Purchase is {{ message }} check your profile more details
                    </div> -->
                    <div
                      class="text-center"
                      style="background-color: #28e007; padding: 20px 0px"
                    >
                      <div>
                        <i class="fa fa-check" style="color: white"></i>
                        <h5 style="color: white">Success</h5>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </b-modal>
          <FlashMessage></FlashMessage>
          <div class="modal-mask" v-if="showModal == true">
            <div class="modal-container">
              <button class="close" @click="modelClose()">x</button>
              <div class="modal-header">
                <h3>! Warning</h3>
              </div>

              <div class="modal-body">
                <label class="form-label">
                  Are you sure you want {{ optionsMessage }} vehicles details ?
                  This action cannot be reversed !.
                </label>
                <label
                  for="validate"
                  v-if="message == 'Success'"
                  style="color: rgb(70 185 127)"
                  ><Strong>{{ message }}</Strong>
                </label>
                <label for="validate" v-else style="color: rgb(233 119 119)">{{
                  message
                }}</label>
              </div>

              <div class="modal-footer delP">
                <button
                  style="
                   color: #f2f2f2;
                    background-color: #222222;
                    border-color: #222222;
                  "
                  class="btn btn-info"
                  @click="modelClose()"
                >
                  No
                </button>
                <button
                  style="width: 85px"
                  class="btn btn-danger"
                  @click="confirmdeletion"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      vehiclenfo: "",
      message: "",
      images: [],
      path: Config.BASE_URL_IMG,
      activities: ["Yes", "No"],
      radioButton: [
        { name: "Yes", id: true },
        { name: "No", id: false },
      ],
      isHidden: false,
      isHidden2: false,
      isHidden3: false,
      isHidden4: false,
      isHidden5: false,
      isHidden6: false,
      isHidden7: false,
      isHidden8: false,
      isHidden9: false,
      isHidden10: false,
      isHidden11: false,
      isHidden12: false,
      isHidden13: false,
      isHidden14: false,
      isHidden15: false,
      isHidden16: false,
      isHidden17: false,
      isHidden18: false,
      isHidden19: false,
      isHidden20: false,
      isHidden21: false,
      isHidden22: false,
      isHidden23: false,
      isHidden24: false,
      isHidden25: false,
      isHidden26: false,
      isHidden27: false,
      isHidden28: false,
      isHidden29: false,
      isHidden30: false,

      activite: "",
      States: "",
      st: "",
      mod: "",
      brand: "",
      authuser: "",
      Districts: "",
      City: "",
      locId: "",
      sellerNamemessage: "",
      sellerEmailmessage: "",
      sellerPhonemessage: "",
      statemessage: "",
      citymessage: "",
      districtmessage: "",
      imagesmessage: "",
      pricemessage: "",
      images1: [],
      images2: [],
      images3: [],
      images4: [],
      images5: [],
      images6: [],
      images7: [],
      images8: [],
      images9: [],
      images10: [],
      images11: [],
      images12: [],
      images13: [],
      images14: [],
      images15: [],
      images16: [],
      images17: [],
      images18: [],
      images19: [],
      images20: [],
      images21: [],
      images22: [],
      images23: [],
      images24: [],
      images25: [],
      images26: [],
      images27: [],
      images28: [],
      images29: [],
      images30: [],
      locationId: "",
      VechTypemessage: "",
      VechBrandmessage: "",
      VechModelmessage: "",
      Vechvariantemessage: "",
      descriptionmessage: "",
      VechType: [],
      VechBrand: [],
      VechModel: [],
      VechVariant: [],
      vechApproval: "",
      imagesList: [],
      FuelType: [],
      active: "false",
      initialLoading: true,
      InsuranceTypeList: [],
      dropdownstyle: "display:none",
      showModal: false,
      optionsMessage: "",
      imagesVeh: [],
      imagesFileVeh: [],
      defaultImageIndex: 0,
    };
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.authuser = this.$store.state.auth.user[0];
      this.getVehiclesDetailsvehId();
      this.getAllStates();
      this.getInsuranceTypes();
      this.getVehicleTypes();
      this.getVehicleFuelType();
      this.getVehicleModel("");
      // this.getVariantList();
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }

    // this.flashMessage.error({title: 'Error Message Title', message: 'Oh, you broke my heart! Shame on you!'});
  },
  methods: {
    // disableInputs() {
    //   var myDiv = document.getElementById("myaccount-details");
    //   var inputs = myDiv.getElementsByTagName("input");

    //   for (var i = 0; i < inputs.length; i++) {
    //     inputs[i].disabled = true;
    //   }
    // },
    goBack() {
      history.back();
    },

    clearAllFields() {
      this.vehiclenfo.model = "";
      this.vehiclenfo.variant = "";
    },
    clearVarientFields() {
      this.vehiclenfo.variant = "";
    },
    getModelList(make) {
      this.vehiclenfo.model = "";
      this.vehiclenfo.variant = "";
      this.getVehicleModel(make);
    },
    getVariantList(model) {
      this.vehiclenfo.variant = "";
      this.getVehicleVariant(model);
    },
    // getVariantFinalList(variant) {
    //   this.vehiclenfo.variant = variant;
    // },
    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
    },
    getVehicleTypes() {
      userService
        .getVehicleTypes()
        .then((response) => {
          this.VechType = response.data.Data;
          // console.log(this.VechType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleFuelType() {
      userService
        .getVehicleFuelType()
        .then((response) => {
          this.FuelType = response.data.Data;
          //console.log(this.FuelType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleBrand() {
      userService
        .getVehicleBrand()
        .then((response) => {
          this.VechBrand = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleModel(make) {
      userService
        .getVehicleModel(make)
        .then((response) => {
          this.VechModel = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleVariant(model) {
      userService
        .getVehicleVariant(model)
        .then((response) => {
          this.VechVariant = response.data.Data;
          // console.log(this.VechVariant);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getInsuranceTypes() {
      userService
        .getInsuranceTypes()
        .then((response) => {
          this.InsuranceTypeList = response.data.Data;
          // console.log(this.VechBrand);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },

    vehprice() {
      this.pricemessage = "";
      if (this.vehiclenfo.vehPrice < this.vehiclenfo.vehMinPrice) {
        // console.log("invprice");
        this.pricemessage =
          "Vehicle price must be greater than vehicle minimum price";
      } else {
        this.pricemessage = "";
      }
    },
    vehminprice() {
      this.pricemessage = "";

      if (this.vehiclenfo.vehPrice < this.vehiclenfo.vehMinPrice) {
        this.pricemessage =
          "Vehicle price must be greater than vehicle minimum price";
      } else {
        this.pricemessage = "";
        // console.log("invice");
      }
    },

    getAllStates() {
      let id = this.authuser.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getStatebyLocId()
          .then((response) => {
            this.States = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDistrictbyLocId(event) {
      this.vehiclenfo.district = "";
      this.vehiclenfo.locName = "";
      let id = this.authuser.sellerId;
      let statename = event.target.value;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDistrictbyLocId(statename)
          .then((response) => {
            this.Districts = response.data.Data;
            // console.log(JSON.stringify(response.data.Data));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getLocationByStateId(event) {
      let id = this.authuser.sellerId;
      let district = event.target.value;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLocationByStateId(district)
          .then((response) => {
            this.City = response.data.Data;
            // console.log(JSON.stringify(response.data.Data));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    Hidden1() {
      this.vehiclenfo.vehImage1 = "";
      // let vechId = this.
    },
    Hidden2() {
      this.vehiclenfo.vehImage2 = "";
    },
    Hidden3() {
      this.vehiclenfo.vehImage3 = "";
    },
    Hidden4() {
      this.vehiclenfo.vehImage4 = "";
    },
    Hidden5() {
      this.vehiclenfo.vehImage5 = "";
    },
    Hidden6() {
      this.vehiclenfo.vehImage6 = "";
    },
    Hidden7() {
      this.vehiclenfo.vehImage7 = "";
    },
    Hidden8() {
      this.vehiclenfo.vehImage8 = "";
    },
    Hidden9() {
      this.vehiclenfo.vehImage9 = "";
    },
    Hidden10() {
      this.vehiclenfo.vehImage10 = "";
    },
    Hidden11() {
      this.vehiclenfo.vehImage11 = "";
    },
    Hidden12() {
      this.vehiclenfo.vehImage12 = "";
    },
    Hidden13() {
      this.vehiclenfo.vehImage13 = "";
    },
    Hidden14() {
      this.vehiclenfo.vehImage14 = "";
    },
    Hidden15() {
      this.vehiclenfo.vehImage15 = "";
    },
    Hidden16() {
      this.vehiclenfo.vehImage16 = "";
    },
    Hidden17() {
      this.vehiclenfo.vehImage17 = "";
    },
    Hidden18() {
      this.vehiclenfo.vehImage18 = "";
    },
    Hidden19() {
      this.vehiclenfo.vehImage19 = "";
    },
    Hidden20() {
      this.vehiclenfo.vehImage20 = "";
    },
    Hidden21() {
      this.vehiclenfo.vehImage21 = "";
    },
    Hidden22() {
      this.vehiclenfo.vehImage22 = "";
    },
    Hidden23() {
      this.vehiclenfo.vehImage23 = "";
    },
    Hidden24() {
      this.vehiclenfo.vehImage24 = "";
    },
    Hidden25() {
      this.vehiclenfo.vehImage25 = "";
    },
    Hidden26() {
      this.vehiclenfo.vehImage26 = "";
    },
    Hidden27() {
      this.vehiclenfo.vehImage27 = "";
    },
    Hidden28() {
      this.vehiclenfo.vehImage28 = "";
    },
    Hidden29() {
      this.vehiclenfo.vehImage29 = "";
    },
    Hidden30() {
      this.vehiclenfo.vehImage20 = "";
    },
    getVehiclesDetailsvehId() {
      let sellerId = this.authuser.sellerId;
      let vehId = this.$route.params.url_key;
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getVehiclesDetailsvehId(sellerId, vehId)
          .then((response) => {
            this.vehiclenfo = response.data.Data[0];

            // if (
            //   this.vehiclenfo.isDeletable === 1 ||
            //   this.vehiclenfo.isDeletable === null ||
            //   this.vehiclenfo.isDeletable === ""
            // ) {
            //   this.disableInputs();
            // }

            this.initialLoading = false;
            this.st = response.data.Data[0];

            // this.mod = this.vehiclenfo.model;
            // this.brand = this.vehiclenfo.make;
            this.vechApproval = true;
            let warrnty = JSON.stringify(response.data.Data[0].underWarantee);

            if (warrnty == "true") {
              this.activite = "Yes";
            } else if (warrnty == "false") {
              this.activite = "No";
            }

            this.defaultImageIndex = this.st.defaultImage;
            // this.st.vehmake = this.vehiclenfo.brandName ;
          })
          .catch((e) => {
            console.log(e);
            this.$router.push("/MyVehicleListings").catch(() => {
              "MyVehicleListings";
              this.initialLoading = false;
            });
          });
      }
    },
    onFileVehicleHistoryChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createVehicleHistoryImage(files);
      //console.log(this.imagesVeh);
    },
    createVehicleHistoryImage(files) {
      var vm = this;
      vm.imagesVeh = [];
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.imagesVeh.push(imageUrl);
          // vm.imagesFileVeh.push(imageUrl);
        };

        reader.readAsDataURL(files[index]);
      }
    },

    onFileChange1(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images1.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange2(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images2.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange3(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images3.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange4(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images4.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange5(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images5.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange6(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images6.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange7(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images7.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange8(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images8.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange9(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images9.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange10(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images10.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange11(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images11.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange12(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images12.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange13(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images13.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange14(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images14.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange15(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images15.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange16(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images16.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange17(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images17.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange18(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images18.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange19(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images19.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange20(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images20.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange21(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images21.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange22(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images22.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange23(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images23.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange24(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images24.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange25(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images25.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange26(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images26.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange27(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images27.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange28(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images28.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange29(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images29.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },
    onFileChange30(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var vm = this;

      var reader = new FileReader();
      reader.onload = function (event) {
        const imageUrl = event.target.result;
        vm.images30.push(imageUrl);
      };
      reader.readAsDataURL(files[0]);
    },

    updateVechDetails() {
      userService
        .getLocationIdbyLocName(this.vehiclenfo.locName)
        .then((response) => {
          this.locationId = response.data.Data;
          this.updateVechDetailsbyloc();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateVechDetailsbyloc() {
      const carsimages = [];

      if (
        this.vehiclenfo.vehImage1 != "" &&
        (this.images1 === null || this.images1.length === 0)
      ) {
        carsimages[0] = this.vehiclenfo.vehImage1;
      } else {
        carsimages[0] = this.images1[0];
      }
      if (
        (this.vehiclenfo.vehImage2 != "" ||
          this.vehiclenfo.vehImage2 != null) &&
        (this.images2 === null || this.images2.length === 0)
      ) {
        carsimages[1] = this.vehiclenfo.vehImage2;
      } else {
        carsimages[1] = this.images2[0];
      }
      if (
        (this.vehiclenfo.vehImage3 != "" ||
          this.vehiclenfo.vehImage3 != null) &&
        (this.images3 === null || this.images3.length === 0)
      ) {
        carsimages[2] = this.vehiclenfo.vehImage3;
      } else {
        carsimages[2] = this.images3[0];
      }
      if (
        (this.vehiclenfo.vehImage4 != "" ||
          this.vehiclenfo.vehImage4 != null) &&
        (this.images4 === null || this.images4.length === 0)
      ) {
        carsimages[3] = this.vehiclenfo.vehImage4;
      } else {
        carsimages[3] = this.images4[0];
      }
      if (
        (this.vehiclenfo.vehImage5 != "" ||
          this.vehiclenfo.vehImage5 != null) &&
        (this.images5 === null || this.images5.length === 0)
      ) {
        carsimages[4] = this.vehiclenfo.vehImage5;
      } else {
        carsimages[4] = this.images5[0];
      }
      if (
        (this.vehiclenfo.vehImage6 != "" ||
          this.vehiclenfo.vehImage6 != null) &&
        (this.images6 === null || this.images6.length === 0)
      ) {
        carsimages[5] = this.vehiclenfo.vehImage6;
      } else {
        carsimages[5] = this.images6[0];
      }
      if (
        (this.vehiclenfo.vehImage7 != "" ||
          this.vehiclenfo.vehImage7 != null) &&
        (this.images7 === null || this.images7.length === 0)
      ) {
        carsimages[6] = this.vehiclenfo.vehImage7;
      } else {
        carsimages[6] = this.images7[0];
      }
      if (
        (this.vehiclenfo.vehImage8 != "" ||
          this.vehiclenfo.vehImage8 != null) &&
        (this.images8 === null || this.images8.length === 0)
      ) {
        carsimages[7] = this.vehiclenfo.vehImage8;
      } else {
        carsimages[7] = this.images8[0];
      }
      if (
        (this.vehiclenfo.vehImage9 != "" ||
          this.vehiclenfo.vehImage9 != null) &&
        (this.images9 === null || this.images9.length === 0)
      ) {
        carsimages[8] = this.vehiclenfo.vehImage9;
      } else {
        carsimages[8] = this.images9[0];
      }
      if (
        (this.vehiclenfo.vehImage10 != "" ||
          this.vehiclenfo.vehImage10 != null) &&
        (this.images10 === null || this.images10.length === 0)
      ) {
        carsimages[9] = this.vehiclenfo.vehImage10;
      } else {
        carsimages[9] = this.images10[0];
      }
      if (
        (this.vehiclenfo.vehImage11 != "" ||
          this.vehiclenfo.vehImage11 != null) &&
        (this.images11 === null || this.images11.length === 0)
      ) {
        carsimages[10] = this.vehiclenfo.vehImage11;
      } else {
        carsimages[10] = this.images11[0];
      }
      if (
        (this.vehiclenfo.vehImage12 != "" ||
          this.vehiclenfo.vehImage12 != null) &&
        (this.images12 === null || this.images12.length === 0)
      ) {
        carsimages[11] = this.vehiclenfo.vehImage12;
      } else {
        carsimages[11] = this.images12[0];
      }
      if (
        (this.vehiclenfo.vehImage13 != "" ||
          this.vehiclenfo.vehImage13 != null) &&
        (this.images13 === null || this.images13.length === 0)
      ) {
        carsimages[12] = this.vehiclenfo.vehImage13;
      } else {
        carsimages[12] = this.images13[0];
      }
      if (
        (this.vehiclenfo.vehImage14 != "" ||
          this.vehiclenfo.vehImage14 != null) &&
        (this.images14 === null || this.images14.length === 0)
      ) {
        carsimages[13] = this.vehiclenfo.vehImage14;
      } else {
        carsimages[13] = this.images14[0];
      }

      if (
        (this.vehiclenfo.vehImage15 != "" ||
          this.vehiclenfo.vehImage15 != null) &&
        (this.images15 === null || this.images15.length === 0)
      ) {
        carsimages[14] = this.vehiclenfo.vehImage15;
      } else {
        carsimages[14] = this.images15[0];
      }

      if (
        (this.vehiclenfo.vehImage16 != "" ||
          this.vehiclenfo.vehImage16 != null) &&
        (this.images16 === null || this.images16.length === 0)
      ) {
        carsimages[15] = this.vehiclenfo.vehImage16;
      } else {
        carsimages[15] = this.images16[0];
      }

      if (
        (this.vehiclenfo.vehImage17 != "" ||
          this.vehiclenfo.vehImage17 != null) &&
        (this.images17 === null || this.images17.length === 0)
      ) {
        carsimages[16] = this.vehiclenfo.vehImage17;
      } else {
        carsimages[16] = this.images17[0];
      }

      if (
        (this.vehiclenfo.vehImage18 != "" ||
          this.vehiclenfo.vehImage18 != null) &&
        (this.images18 === null || this.images18.length === 0)
      ) {
        carsimages[17] = this.vehiclenfo.vehImage18;
      } else {
        carsimages[17] = this.images18[0];
      }

      if (
        (this.vehiclenfo.vehImage19 != "" ||
          this.vehiclenfo.vehImage19 != null) &&
        (this.images19 === null || this.images19.length === 0)
      ) {
        carsimages[18] = this.vehiclenfo.vehImage19;
      } else {
        carsimages[18] = this.images19[0];
      }

      if (
        (this.vehiclenfo.vehImage20 != "" ||
          this.vehiclenfo.vehImage20 != null) &&
        (this.images20 === null || this.images20.length === 0)
      ) {
        carsimages[19] = this.vehiclenfo.vehImage20;
      } else {
        carsimages[19] = this.images20[0];
      }

      if (
        (this.vehiclenfo.vehImage21 != "" ||
          this.vehiclenfo.vehImage21 != null) &&
        (this.images21 === null || this.images21.length === 0)
      ) {
        carsimages[20] = this.vehiclenfo.vehImage21;
      } else {
        carsimages[20] = this.images21[0];
      }
      if (
        (this.vehiclenfo.vehImage22 != "" ||
          this.vehiclenfo.vehImage22 != null) &&
        (this.images22 === null || this.images22.length === 0)
      ) {
        carsimages[21] = this.vehiclenfo.vehImage22;
      } else {
        carsimages[21] = this.images22[0];
      }
      if (
        (this.vehiclenfo.vehImage23 != "" ||
          this.vehiclenfo.vehImage23 != null) &&
        (this.images23 === null || this.images23.length === 0)
      ) {
        carsimages[22] = this.vehiclenfo.vehImage23;
      } else {
        carsimages[22] = this.images23[0];
      }
      if (
        (this.vehiclenfo.vehImage24 != "" ||
          this.vehiclenfo.vehImage24 != null) &&
        (this.images24 === null || this.images24.length === 0)
      ) {
        carsimages[23] = this.vehiclenfo.vehImage24;
      } else {
        carsimages[23] = this.images24[0];
      }
      if (
        (this.vehiclenfo.vehImage25 != "" ||
          this.vehiclenfo.vehImage25 != null) &&
        (this.images25 === null || this.images25.length === 0)
      ) {
        carsimages[24] = this.vehiclenfo.vehImage25;
      } else {
        carsimages[24] = this.images25[0];
      }
      if (
        (this.vehiclenfo.vehImage26 != "" ||
          this.vehiclenfo.vehImage26 != null) &&
        (this.images26 === null || this.images26.length === 0)
      ) {
        carsimages[25] = this.vehiclenfo.vehImage26;
      } else {
        carsimages[25] = this.images26[0];
      }
      if (
        (this.vehiclenfo.vehImage27 != "" ||
          this.vehiclenfo.vehImage27 != null) &&
        (this.images27 === null || this.images27.length === 0)
      ) {
        carsimages[26] = this.vehiclenfo.vehImage27;
      } else {
        carsimages[26] = this.images27[0];
      }
      if (
        (this.vehiclenfo.vehImage28 != "" ||
          this.vehiclenfo.vehImage28 != null) &&
        (this.images28 === null || this.images28.length === 0)
      ) {
        carsimages[27] = this.vehiclenfo.vehImage28;
      } else {
        carsimages[27] = this.images28[0];
      }
      if (
        (this.vehiclenfo.vehImage29 != "" ||
          this.vehiclenfo.vehImage29 != null) &&
        (this.images29 === null || this.images29.length === 0)
      ) {
        carsimages[28] = this.vehiclenfo.vehImage29;
      } else {
        carsimages[28] = this.images29[0];
      }
      if (
        (this.vehiclenfo.vehImage30 != "" ||
          this.vehiclenfo.vehImage30 != null) &&
        (this.images30 === null || this.images30.length === 0)
      ) {
        carsimages[29] = this.vehiclenfo.vehImage30;
      } else {
        carsimages[29] = this.images30[0];
      }

      this.vehiclenfo.locationId = this.locationId[0].locId;
      this.vehiclenfo.sp = "updVehicle";
      this.vehiclenfo.sellerId = this.authuser.sellerId;
      this.vehiclenfo.vehTypeId = this.st.vehTypeId;

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.vehiclenfo && this.st) {
            this.message = "";
            let docImage = "";
            if (this.imagesVeh == "") {
              docImage = this.vehiclenfo.vehicleHistoryUrl;
            } else {
              docImage = this.imagesVeh[0];
            }
       
            userService
              .updateVechDetails(
                this.vehiclenfo,
                carsimages,
                docImage,
                this.defaultImageIndex
              )
              .then((response) => {
                this.message = response.data.Message;
                if (this.message == "Success") {
                  this.makeToast(
                    "success",
                    "Vehicle details updated successfully!"
                  );
                  setTimeout(() => {
                    location.reload();
                  }, 3000);
                  // this.flashMessage.success({
                  //   title: this.message,
                  //   message: "Vehicle details updated successfully!",

                  //   flashMessageStyle: {
                  //     backgroundColor: "linear-gradient(#e66465, #9198e5)",
                  //   },
                  // });
                } else {
                  this.makeToast("danger", "!Oops sonthing went wrong");
                }

                window.scrollTo(0, 0);
              })
              .catch((e) => {
                // this.message = e.response.data.Message;

                this.makeToast("danger", e.response.data.Message);

                // this.flashMessage.error({
                //   title: "Error!",
                //   time: 3000,
                //   message: this.message,
                // });
              });
          }
          // this.message = "Uploaded!";
          return;
        }
        // this.message = "Check all Fields";
        // this.flashMessage.warning({
        //   title: "Warning",
        //   time: 3000,
        //   message: this.message,
        // });
        // this.closeModal();
        window.scrollTo(0, 0);
      });
    },
    closeModal() {
      var self = this;
      setTimeout(function () {
        self.hideModal();
      }, 1000);
    },
    hideModal() {
      this.$refs["modalsave"].hide();
      window.scrollTo(0, 0);
    },

    deleteVehicle() {
      if (this.vehiclenfo) {
        this.showModal = true;
        this.optionsMessage = "delete";
        // console.log(this.vehiclenfo);
      }
    },
    confirmdeletion() {
      if (this.vehiclenfo) {
        let sellerId = this.authuser.sellerId;
        userService
          .deleteVehicle(sellerId, this.vehiclenfo.vehId)
          .then((response) => {
            // console.log(response);
            if (response.data.Message == "Success") {
              this.makeToast("success", "Vehicle deleted successfully!");

              var self = this;
              self.modelClose();
              setTimeout(function () {
                self.$router.push("/MyVehicleListings").catch(() => {
                  "MyVehicleListings";
                });
              }, 2000);

              // this.flashMessage.success({
              //   title: this.message,
              //   message: "Vehicle deleted successfully!",

              //   flashMessageStyle: {
              //     backgroundColor: "linear-gradient(#e66465, #9198e5)",
              //   },
              // });
            }
          })
          .catch((e) => {
            console.log(e);

            this.$router.push("/MyVehicleListings").catch(() => {
              "MyVehicleListings";
            });
          });
      }
    },
    modelClose() {
      this.message = "";
      this.showModal = false;
      this.optionsMessage = "";
    },

    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/UpdateVechDetails").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
    this.getVehicleBrand();

    // this. getVehicleModel();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filterByBrand() {
      return this.VechBrand.filter((item) => {
        return item.make
          .toLowerCase()
          .includes(this.vehiclenfo.make.toLowerCase());
      });
    },
    filterByModel() {
      return this.VechModel.filter((item) => {
        return item.model
          .toLowerCase()
          .includes(this.vehiclenfo.model.toLowerCase());
      });
    },
  },
};
</script>
<style>
.uren-form-inner {
  display: block !important;
}
.uren-form .uren-form-inner .single-input input:first-child,
.uren-form-boxed .uren-form-inner .single-input input:first-child {
  margin-top: 0px;
}
.loadImage {
  width: 150px;
  height: 78px;
  object-fit: cover;
  margin: 10px;
}
.imagesfromdb div {
  display: grid;
  width: 160px;
}
.vehSelection {
  position: absolute;
  background-color: white;
  width: 100%;
  padding-left: 10px;
  z-index: 1;
}
.errorMes {
  color: red;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 350px;
  margin: 40px auto 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: -15px;
  color: #df765c;
}
.modal-header {
  border-bottom: none !important;
}

.modal-body {
  margin: 0;
  padding: 0px 15px;
}

.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
  font-size: 14px;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}
.modal-footer {
  border-top: none !important;
}

.modal-enter,
.modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

button.btnImageRemove {
  border: 1px solid #c7c7c7;
  border-radius: 3px;
  margin: 0px 0px 10px 10px;
  color: #ffffff;
  background: #e97068;
}

.fileSelect {
  margin-left: 10px;
}

.back-button {
  position: absolute;
  top: 8px;
  left: 90%;
}
</style>
